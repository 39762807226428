<template>
  <el-dialog
    title="Neuen Veranstaltungsort anlegen"
    :visible.sync="visible"
    width="450px"
  >
    <el-form
      ref="locationForm"
      :model="location"
      label-position="top"
      :rules="rules"
    >
      <el-form-item label="Name" prop="name">
        <el-input v-model="location.name" v-focus></el-input>
      </el-form-item>

      <el-form-item label="Straße und Hausnummer" prop="address">
        <el-input v-model="location.address"></el-input>
      </el-form-item>

      <el-row :gutter="40">
        <el-col :span="12">
          <el-form-item label="Postleitzahl" prop="zipcode">
            <el-input v-model="location.zipcode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Ort" prop="city">
            <el-input v-model="location.city"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="changeVisibility(false)">Abbrechen</el-button>
      <el-button type="primary" :loading="saving" @click="checkForm"
        >Ort anlegen</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import FormMixin from "@/mixins/form.js"

export default {
  mixins: [FormMixin],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      location: {}
    }
  },
  computed: {
    rules() {
      return {
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ],
        address: [
          { required: true, message: "Bitte Adresse angeben", trigger: "blur" }
        ],
        zipcode: [
          {
            required: true,
            message: "Bitte gültige PLZ angeben",
            trigger: "blur"
          }
        ],
        city: [
          { required: true, message: "Bitte Ort angeben", trigger: "blur" }
        ]
      }
    }
  },
  watch: {
    show: function(val) {
      this.visible = val
    },
    visible: function(val) {
      this.$emit("update:show", val)
    }
  },
  methods: {
    changeVisibility(val) {
      this.$emit("update:show", val)
    },
    checkForm() {
      this.$refs["locationForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          this.showFormErrors()
        }
      })
    },
    async submitForm() {
      this.$emit("form-submitted", this.location)
    }
  }
}
</script>
